$('body').append('<div class="owl__lightbox--helper" style="display: none;"></div>');

		class LightboxFallback extends HTMLDivElement {
			constructor() {
				super();
			}

			connectedCallback() {
				var href  = this.dataset.href;
				var title = this.dataset.title;
				var link  = document.querySelector(`a[href="${href}"]`);

				if(!link) {
					var albumName = this.dataset.owlLigthbox;

					link = document.createElement('a');

					link.href = href;
					link.title = title;
					link.innerHTML = title;
					link.dataset.lightbox = albumName;

					$('.owl__lightbox--helper').append(link);
				}

				$(this).click(function() {
					link.click();
				});

				if(!window.hasLightbox) {
					loadLightbox();
				}
			}

		}

		customElements.define('lightbox-fallback', LightboxFallback, {extends: 'div'});
