// haslightbox
(function(){
	let lightboxItens = $('[data-lightbox]');

	if(lightboxItens.length > 0){
		loadLightbox();
	}

})();

function loadLightbox() {
	let css = document.createElement('link');

	css.rel = 'stylesheet';
	css.href = 'https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.1/css/lightbox.min.css';
	css.type = 'text/css';

	document.head.appendChild(css);

	let js = document.createElement('script');

	js.src = 'https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.1/js/lightbox.min.js';

	document.body.appendChild(js);
	window.hasLightbox = true;
}
