class QtdeToggler extends HTMLDivElement {
	constructor() {
		super();

		let state = {};

		let input = this.querySelector('input');
		let minus = this.querySelector('[data-fn="minus"]');
		let plus  = this.querySelector('[data-fn="plus"]');

		input.insertAdjacentHTML('afterend', '<div class="form-control output"></div>');

		let output = this.querySelector('.output');

		input.classList.add('d-none');

		state.value      = parseInt(input.value);
		output.innerHTML = input.value;

		Object.defineProperty(state, 'count', {
			set(value) {
				state.value = value;
				input.value = value;
				output.innerHTML = value;
			},
			get() {
				return state.value;
			}
		})

		minus.addEventListener('click', e => {
			e.preventDefault();

			if(state.count > 1) {
				state.count--
			}
		});

		plus.addEventListener('click', e => {
			e.preventDefault();

			state.count++
		});
	}
}

customElements.define('qtde-toggler', QtdeToggler, {extends: 'div'});
